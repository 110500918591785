import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic01 from '../assets/images/jonathan-agoot-serverless-computing.jpg'
import pic02 from '../assets/images/augmented-reality-virtual-reality.jpg'
import pic03 from '../assets/images/voice-user-interfaces.jpg'
// import pic04 from '../assets/images/conversion-rate-optimization.jpg'
// import pic05 from '../assets/images/web3d.jpg'

const Landing = props => (
  <Layout>
    <div>
      <Helmet>
        <title>Portfolio | Jonathan Agoot</title>
        <meta name="description" content="Jonathan Agoot - Portfolio" />
      </Helmet>
      <section id="banner" className="style2">
        <div className="inner">
          <header className="major">
            <h1>Portfolio</h1>
          </header>
          <div className="content">
            <p>Adventures in the Digital World</p>
          </div>
        </div>
      </section>
      <div id="main">
        <div className="content">
          <div className="inner">
            <p className="box">
              Listed below is a growing list of projects and prototypes. I will
              be adding more as I complete new projects!
            </p>
          </div>
        </div>
        <section id="two" className="spotlights">
          <section>
            <Link
              title="Jonathan Agoot - Serverless Computing and Machine Learning"
              to="/portfolio/serverless-computing-and-machine-learning"
              className="image"
            >
              <img
                src={pic01}
                alt="Jonathan Agoot - Serverless Computing and Machine Learning"
              />
            </Link>
            <div className="content">
              <div className="inner">
                <header className="major">
                  <h3>
                    Serverless Computing
                    <br />
                    and Machine Learning
                  </h3>
                </header>
                <p>
                  In 2015, I got a lot more serious with Amazon Web
                  Services(AWS) and started to prototype very simple ideas,{' '}
                  <u>like this website</u>, but with serverless architecture
                  focus.
                </p>
                <p>
                  My first complex project using serverless computing was with a
                  machine learning project to create a near real-time product
                  recommendations delivered at scale for eCommerce and digital
                  marketing applications.
                </p>
                <ul className="actions">
                  <li>
                    <Link
                      title="Jonathan Agoot - Serverless Computing and Machine Learning"
                      to="/portfolio/serverless-computing-and-machine-learning"
                      className="button"
                    >
                      Read on
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <Link
              title="Jonathan Agoot - Extended Reality (XR)"
              to="/portfolio/virtual-reality"
              className="image"
            >
              <img src={pic02} alt="Jonathan Agoot - Extended Reality (XR)" />
            </Link>
            <div className="content">
              <div className="inner">
                <header className="major">
                  <h3>Extended Reality (XR)</h3>
                </header>
                <p>
                  Extended Reality (XR) consists of a emerging technology
                  ecosystem that is continually expanding into the marketplace
                  across man types of traditional devices and emerging hardware.
                  Here's a case study on how I've helped bring virtual reality
                  to Thermo Fisher Scientific, a Fortune 500 life sciences and
                  biotechnology company.
                </p>
                <ul>
                  <li>Virtual Reality</li>
                  <li>Augmented Reality</li>
                  <li>Web 3D</li>
                  <li>Web VR</li>
                  <li>Web AR</li>
                </ul>
                <ul className="actions">
                  <li>
                    <Link to="/portfolio/virtual-reality" className="button">
                      Read on
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <Link
              title="Jonathan Agoot - Voice User Interface"
              to="/portfolio/voice-user-interface"
              className="image"
            >
              <img src={pic03} alt="Jonathan Agoot - Voice User Interface" />
            </Link>
            <div className="content">
              <div className="inner">
                <header className="major">
                  <h3>Voice User Interfaces (VUI)</h3>
                </header>
                <p>
                  Voice user interfaces continue to become much more accurate
                  and useful. The real challenge to adoption relies on a voice
                  application that can replace a user's current solution. Here
                  are projects and prototypes I've created to help advance the
                  conversation to ideation.
                </p>
                <ul>
                  <li>SDK: Amazon Alexa</li>
                  <li>Devices: Amazon Echo, Echo Show, Smartphone</li>
                  <li>SDK: Google Actions</li>
                  <li>Devices: Google Assistant, Smartphone</li>
                </ul>
                <ul className="actions">
                  <li>
                    <Link
                      title="Jonathan Agoot - Voice User Interface"
                      to="/portfolio/voice-user-interface"
                      className="button"
                    >
                      Read on
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          {/* <section>
						<Link title="Jonathan Agoot - Conversion Rate Optimization (CRO)" to="/portfolio/conversion-rate-optimization" className="image">
							<img src={pic04} alt="Jonathan Agoot - Conversion Rate Optimization (CRO)" />
						</Link>
						<div className="content">
							<div className="inner">
								<header className="major">
									<h3>Conversion Rate Optimization (CRO)</h3>
								</header>
								<p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
								<ul className="actions">
									<li>
										<Link title="Jonathan Agoot - Conversion Rate Optimization (CRO)" to="/portfolio/conversion-rate-optimization" className="button">Learn more</Link>
									</li>
								</ul>
							</div>
						</div>
					</section> */}
        </section>
      </div>
    </div>
  </Layout>
)

export default Landing
